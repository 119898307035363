class searchFrom {
  validateSort = (rule, value, callback) => {
    if (value) {
      if (value <= 0) {
        callback([new Error('数值应为正整数')]);
      } else if (value > 9999) {
        callback([new Error('数值需小于10000')]);
      } else {
        callback();
      }
    } else {
      callback([new Error('请输入排列顺序')]);
    }
  }

  constructor(type) {
    if (type === 'form') {
      this.vrName = ''
      this.vrPic = ''
      this.completePath = ''
      this.vrPath = ''
      this.sort = ''
      this.top = 'Y'
      this.point = {}
      this.latitude = ''
      this.longitude = ''
      this.mapAnnotation = ''
    } else if (type === 'rule') {
      this.vrName = [
        {
          required: true,
          message: '请输入VR名称',
          trigger: 'change',
        },
      ]
      this.completePath = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
      this.vrPath = [
        {
          required: true,
          message: '请输入VR地址',
          trigger: 'change',
        },
      ]
      this.sort = [
        {
          required: true,
          validator: this.validateSort,
          trigger: ['change', 'blur'],
        },
      ]
      this.top = [
        {
          required: true,
          message: '请选择',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
