<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="VR名称" prop="title">
              <el-input clearable v-model="form.vrName" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="12" :span="6" class="text-right">
            <el-button type="primary" @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
            <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight - 20"
				style="width: 100%"
        border
        @sort-change='sort_post_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <el-table-column
					type="index"
          align="center"
					label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
          header-align="center"
          align="left"
          min-width="200"
          show-overflow-tooltip
					label="VR名称">
          <template slot-scope="scope">
            <div class="img_text">
              <img width="40" height="40" :src="scope.row.completePath" alt="">
              <span style="margin-left: 10px;vertical-align: middle">{{ scope.row.vrName }}</span>
            </div>
          </template>
				</el-table-column>
				<el-table-column
					prop="sort"
          align="center"
          min-width="180"
          sortable='custom'
          show-overflow-tooltip
					label="显示顺序">
				</el-table-column>
        <el-table-column
					prop="vrPath"
          align="center"
          min-width="180"
          show-overflow-tooltip
					label="VR地址">
				</el-table-column>
        <el-table-column
          align="center"
					prop="publishBy"
          min-width="105px"
					label="发布者">
				</el-table-column>
        <el-table-column
					prop="publishTime"
          align="center"
          min-width="180"
          sortable='custom'
          show-overflow-tooltip
					label="发布时间">
				</el-table-column>
        <el-table-column
          align="center"
					prop="clickCount"
          sortable='custom'
          min-width="180"
          show-overflow-tooltip
					label="总阅读量">
				</el-table-column>
        <el-table-column
          align="center"
					prop="top"
          :formatter="topFormatter"
          min-width="105"
          show-overflow-tooltip
					label="是否置顶">
				</el-table-column>
        <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="120">
          <template slot-scope="scope">
            <el-button v-if="buttonAuth.includes('vrManage:view:updateVr')" @click="changeAddDialog(true, true, scope.row)" type="text" size="small">编辑</el-button>
            <el-button v-if="buttonAuth.includes('vrManage:view:updatePublishStatus')" @click="openDeleteDialog(scope.row.id, scope.row.publishStatus)" style="margin-left:20px" type="text" size="small">{{scope.row.publishStatus === 1 ? '下架' : '上架'}}</el-button>
          </template>
        </el-table-column>
			</el-table>
    </div>
    <div class="pageination-wrapper">
      <div>
        <el-button class="default-button" v-if="buttonAuth.includes('vrManage:view:addVr')" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
        <el-button class="default-button" v-if="buttonAuth.includes('vrManage:view:updateVr')" :disabled="selectedArr.length !==1" @click="changeAddDialog(true, true, selectedArr[0])">修改</el-button>
        <el-button class="default-button" v-if="buttonAuth.includes('vrManage:view:delVr')" :disabled="selectedArr.length ===0" @click="openDeleteDialog(selectedArr.map(item => item.id), 4)">{{$t('delete')}}</el-button>
        <el-button class="default-button" v-if="buttonAuth.includes('vrManage:view:topVr')" :disabled="selectedArr.length ===0" @click="openDeleteDialog(selectedArr.filter(item => item.top === selectedArr[0].top).map(item => item.id), 3)">{{selectedArr.map(item => item.top)[0] ==='Y'?'取消置顶':'置顶'}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :text="confirmDialog.text" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/index'
import VRManage from './vrManage.js'
import apis from '@/apis'
import addDialog from './dialog/addDialog.vue'
import confirmDialog from '@/components/confirmDialogNew.vue'

export default {
  mixins: [mixin],
  components: {
    addDialog,
    confirmDialog,
  },
  data() {
    return {
      form: new VRManage("form"),
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        text: "",
        data: {},
      },
      matchStatus: 0, // 0 发布 1 下架 2 禁用
    }
  },
  props: {},
  watch: {},
  computed: {
    ...mapState(['Language', 'DROPDOWNBOX']),
  },
  methods: {
    handleSelectionChange(arr) {
      console.log(arr)
      this.selectedArr = arr
      console.log([...new Set(arr.map(item => item.publishStatus))])
      if ([...new Set(arr.map(item => item.publishStatus))].length === 1 && arr[0].publishStatus === 0) {
        this.matchStatus = 0
      } else if ([...new Set(arr.map(item => item.publishStatus))].length === 1 && arr[0].publishStatus === 1) {
        this.matchStatus = 1
      } else if ([...new Set(arr.map(item => item.publishStatus))].length !== 1) {
        this.matchStatus = 2
      }
    },
    topFormatter(row, column, cellValue) {
      return cellValue === 'Y' ? '是' : '否';
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 4) {
        console.log(this.confirmDialog.data.ids)
        this.$http.delete(`${apis.delVr}?id=${this.confirmDialog.data.ids}`).then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog()
            this.$message.success("删除成功!");
          }
        })
      } else if (this.confirmDialog.type === 3) {
        this.$http.put(apis.topVr, {
          ids: this.confirmDialog.data.ids,
          top: this.selectedArr[0].top === 'Y' ? 'N' : 'Y',
        }).then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog()
            this.$message.success("修改成功!");
          }
        })
      } else {
        this.$http.put(apis.updateVrPublishStatus, {
          ids: this.confirmDialog.data.ids.split(','),
          publishStatus: this.confirmDialog.type + 1 < 3 ? this.confirmDialog.type + 1 : 1,
        }).then((res) => {
          if (res.data.code === 0) {
            this.$refs.confirmDialog.closeDialog()
            this.$message.success("修改成功!");
          }
        })
      }
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id, type) {
      let text = ""
      switch (type) {
        case 0:
        case 2:
          text = "您确认要上架该数据吗?"
          break;
        case 1:
          text = "您确认要下架该数据吗?"
          break;
        case 3:
          if (this.selectedArr[0].top === "Y") {
            text = "您确认要取消置顶该数据吗?"
          } else {
            text = "您确认要置顶该数据吗?"
          }
          break;
        default:
          text = "您确认要删除该数据吗?"
          break;
      }
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = []
      this.confirmDialog.data.ids = id
      this.confirmDialog.type = type
      this.confirmDialog.text = text
      this.changeConfirmDialog(true, type)
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.getVrManagelist, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
     /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new VRManage('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.img_text{
  display: flex;
  align-items: center;
}
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
</style>
